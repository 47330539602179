<template>
  <div class="calculator-settings">
    <back-with-title
      title="Calculator Settings"
    />
    <b-tabs
      v-model="tabIndex"
      nav-class="nav-left"
      nav-wrapper-class="col-md-2"
      vertical
      pills
    >
      <b-tab :lazy="lazyRegions">
        <template #title>
          <feather-icon icon="MapIcon" />
          Regions
        </template>
        <regions
          :tab-changed="tabIndex !== 0"
          @update-coverage-types="() => { tabIndex = 1 }"
          @no-lazy="() => { lazyRegions = false }"
        />
      </b-tab>

      <b-tab :lazy="lazyCoverageTypes">
        <template #title>
          <feather-icon icon="ShieldIcon" />
          Coverage Types
        </template>
        <coverage-types
          @no-lazy="() => { lazyCoverageTypes = false }"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import Regions from './Regions.vue'
import CoverageTypes from './CoverageTypes.vue'

export default {
  components: {
    BTabs,
    BTab,
    BackWithTitle,
    Regions,
    CoverageTypes,
  },
  data() {
    return {
      tabIndex: 0,
      regionToEdit: null,
      lazyRegions: true,
      lazyCoverageTypes: true,
    }
  },
}
</script>

<style>
.calculator-settings .w-10 {
  width: 10% !important;
}
</style>
